var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"order-sent"},[_vm._m(0),_c('div',{staticClass:"order-sent__footer"},[_c('MainButton',{attrs:{"title":"К списку консультантов","color":"gold"},on:{"click":function($event){return _vm.$router.push({ name: 'TransactionSupportTsp', replace: true })}}}),_c('MainButton',{attrs:{"title":"Перейти к заявке","color":"outlined"},on:{"click":function($event){return _vm.$router.push({
          name: 'HistoryCurrentService',
          replace: true,
          params: { id: _vm.idOrder },
          query: { service: 'received' },
        })}}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order-sent__box"},[_c('span',{staticClass:"order-sent__icon icon-circle-done-fill"}),_c('div',{staticClass:"order-sent__content"},[_c('h2',[_vm._v("Заявка отправлена")]),_c('p',[_vm._v(" После одобрения заявки вы сможете связаться с консультантом любым удобным способом для оказания услуги ")])])])
}]

export { render, staticRenderFns }