<script>
import MainButton from "@/components/helpers/MainButton.vue";

export default {
  name: "OrderSent",
  components: { MainButton },
  props: {
    idOrder: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {},

  methods: {},
};
</script>

<template>
  <section class="order-sent">
    <div class="order-sent__box">
      <span class="order-sent__icon icon-circle-done-fill"></span>

      <div class="order-sent__content">
        <h2>Заявка отправлена</h2>

        <p>
          После одобрения заявки вы сможете связаться с консультантом любым
          удобным способом для оказания услуги
        </p>
      </div>
    </div>

    <div class="order-sent__footer">
      <MainButton
        title="К списку консультантов"
        color="gold"
        @click="$router.push({ name: 'TransactionSupportTsp', replace: true })"
      />
      <MainButton
        title="Перейти к заявке"
        color="outlined"
        @click="
          $router.push({
            name: 'HistoryCurrentService',
            replace: true,
            params: { id: idOrder },
            query: { service: 'received' },
          })
        "
      />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.order-sent {
  color: $dark-primary;
  display: flex;
  flex-direction: column;
  gap: $space-xl;

  @media (max-width: 1023px) {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr min-content;
  }

  &__box {
    display: flex;
    flex-direction: column;
    gap: $space-xl;

    @media (max-width: 1023px) {
      gap: $space-m;
      align-items: center;
      align-self: center;
    }
  }

  &__icon {
    height: 60px;
    width: 60px;
    background: green;

    @media (max-width: 1023px) {
      width: 48px;
      height: 48px;
    }
  }

  &__content {
    color: inherit;

    @media (max-width: 1023px) {
      text-align: center;
    }

    > h2 {
      @include title-3;

      margin-bottom: $space-m;

      @media (max-width: 1023px) {
        margin-bottom: $space-s;
      }
    }

    > p {
      @include body-1;
    }
  }

  &__footer {
    display: flex;
    gap: $space-s;

    @media (max-width: 1023px) {
      flex-direction: column;
    }

    & > button {
      width: fit-content;
      padding-left: $space-l;
      padding-right: $space-l;

      @media (max-width: 1023px) {
        width: 100%;
      }
    }
  }
}
</style>
