<script>
import OrderSent from "@/components/transaction-support/OrderSent.vue";
import { mapActions } from "vuex";

export default {
  name: "CompleteOrderService",
  components: { OrderSent },

  data() {
    return {
      idOrder: "",
    };
  },

  computed: {},

  methods: {
    ...mapActions({
      checkUser: "auth/checkUser",
    }),
  },

  created() {
    this.checkUser();
    const { isAllowedPage, idOrder } = this.$route.params;
    this.idOrder = idOrder;

    if (!isAllowedPage)
      this.$router.push({ name: "OtherServicesTsp", replace: true });
  },
};
</script>

<template>
  <div class="complete-order-service default-content-padding">
    <OrderSent v-if="idOrder" :id-order="idOrder" />
  </div>
</template>

<style lang="scss" scoped>
.complete-order-service {
  width: 100%;
  height: 100%;
  background: $background-white;
  padding-top: 48px;

  @media (max-width: 1023px) {
    padding-top: 40px;
    padding-bottom: $space-l;
  }
}
</style>
